<template>
  <div class="order__container"></div>
</template>

<script>
	import { paymentStore } from "../store/store_payment";
	import { useStore } from  "../store/store_pinia"

	export default {
		name: "order",
    data() {
	    const payment = paymentStore();
	    const store = useStore();
			return {
				store,
				payment,
				pk: '',
				email: '',
        order: '',
      }
    },
		created() {
			this.pk = this.$route.query.pk;
			this.email = this.$route.query.email;
			this.order = this.$route.query.order;
			let str = window.location.search;
			const searchParams = new URLSearchParams(str);

      this.payments();
		},
		methods: {
			logout() {
				this.store.logout().then((data) => {
				})
			},
			payments() {
				this.logout()
				this.payment.getPaymentParams(this.pk, this.email, this.order).then((data) => {
					this.required = data.redirect;

					window.location.href = this.required;
				})
			},
		}
	}
</script>

<style scoped>

</style>