import {createApp, h} from 'vue'
import { createPinia } from 'pinia'


import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import 'vue-select/dist/vue-select.css';
import './style.scss'
import App from './App.vue'
import router from './router'
import vSelect from 'vue-select'
import PerfectScrollbar from 'vue3-perfect-scrollbar'


const OPEN_INDICATOR = '<span><svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 17V1M8 17L1 10M8 17L15 10" stroke="#001E3A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>🔽</span>';


vSelect.props.components.default = () => ({
	Deselect: {
		render: createElement => h('span'),
	},
	OpenIndicator: {
		render: createElement => h('span'),
	},
});

const app = createApp(App);

app.component('v-select', vSelect);
app.use(router);
app.use(PerfectScrollbar);
app.use(createPinia())
app.mount('#app');