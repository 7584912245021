<template>
  <div class="form__title">
    <h1 class="title">{{ text[0] }}</h1>
    <p class="text">{{ text[1] }}</p>
  </div>
</template>

<script>
  export default {
  	props: {
  		text: {
  			type: Array,
      },
    }
  }
</script>

<style scoped lang="scss">
  .form__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    grid-gap: 10px;
  }

  .title {
    font-size: 29px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;

    @media (max-width: 768px) {
      font-weight: 800;
      font-size: 22px;
      line-height: 22px;
    }

    @media (max-width: 320px) {
      font-size: 18px;
      line-height: 18px;
    }
  }

  .text {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 768px) {
      font-size: 12px;
    }

    @media (max-width: 320px) {
      font-size: 10px;
    }
  }
</style>