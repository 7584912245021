export const day = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];

export const month = [
	{name: 'янв.', value: 1},
	{name: 'фев.', value: 2},
	{name: 'мар.', value: 3},
	{name: 'апр.', value: 4},
	{name: 'май.', value: 5},
	{name: 'июн.', value: 6},
	{name: 'июл.', value: 7},
	{name: 'авг.', value: 8},
	{name: 'сен.', value: 9},
	{name: 'окт.', value: 10},
	{name: 'ноя.', value: 11},
	{name: 'дек.', value: 12},
]

export const years = [
	2004, 2003, 2002, 2001, 2000, 1999, 1998, 1997, 1996,
	1995, 1994, 1993, 1992, 1991, 1990,1989,1988,1987,1986,
	1985,1984,1983,1982,1981,1980,1979,1978,1977,1976,1975,
	1974,1973,1972,1971,1970,1969,1968,1967,1966,1965,1964,
	1963,1962,1961,1960,1959,1958,1957,1956,1955,1954,1953
];

export const age = [
	{name: '18 лет', value: 18},
	{name: '19 лет', value: 19},
	{name: '20 лет', value: 20},
	{name: '21 лет', value: 21},
	{name: '22 лет', value: 22},
	{name: '23 лет', value: 23},
	{name: '24 лет', value: 24},
	{name: '25 лет', value: 25},
	{name: '26 лет', value: 26},
	{name: '27 лет', value: 27},
	{name: '28 лет', value: 28},
	{name: '29 лет', value: 29},
	{name: '30 лет', value: 30},
	{name: '31 лет', value: 31},
	{name: '32 лет', value: 32},
	{name: '33 лет', value: 33},
	{name: '34 лет', value: 34},
	{name: '35 лет', value: 35},
	{name: '36 лет', value: 36},
	{name: '37 лет', value: 37},
	{name: '38 лет', value: 38},
	{name: '39 лет', value: 39},
	{name: '40 лет', value: 40},
	{name: '41 лет', value: 41},
	{name: '42 лет', value: 42},
	{name: '43 лет', value: 43},
	{name: '44 лет', value: 44},
	{name: '45 лет', value: 45},
	{name: '46 лет', value: 46},
	{name: '47 лет', value: 47},
	{name: '48 лет', value: 48},
	{name: '49 лет', value: 49},
	{name: '50 лет', value: 50},
	{name: '51 лет', value: 51},
	{name: '52 лет', value: 52},
	{name: '53 лет', value: 53},
	{name: '54 лет', value: 54},
	{name: '55 лет', value: 55},
	{name: '56 лет', value: 56},
	{name: '57 лет', value: 57},
	{name: '58 лет', value: 58},
	{name: '59 лет', value: 59},
	{name: '60 лет', value: 60},
	{name: '61 лет', value: 61},
	{name: '62 лет', value: 62},
	{name: '63 лет', value: 63},
	{name: '64 лет', value: 64},
	{name: '65 лет', value: 65},
	{name: '66 лет', value: 66},
	{name: '67 лет', value: 67},
	{name: '68 лет', value: 68},
	{name: '69 лет', value: 69},
	{name: '70 лет', value: 70},
	{name: '71 лет', value: 71},
	{name: '72 лет', value: 72},
	{name: '73 лет', value: 73},
	{name: '74 лет', value: 74},
	{name: '75 лет', value: 75},
	{name: '76 лет', value: 76},
	{name: '77 лет', value: 77},
	{name: '78 лет', value: 78},
	{name: '79 лет', value: 79},
	{name: '80 лет', value: 80},
	{name: '81 лет', value: 81},
]

export const genders = [
	{name: 'Девушку', value: 2},
	{name: 'Парня', value: 1}
]

export const radius = [
	{name: 'В радиусе 50км', value: 50},
	{name: 'В радиусе 100км', value: 100},
	{name: 'В радиусе 200км', value: 200},
	{name: 'В радиусе 500км', value: 500},
	{name: 'В радиусе 1000км', value: 1000},
	{name: 'В радиусе 3000км', value: 3000},
	{name: 'В радиусе 5000км', value: 5000},
	{name: 'В радиусе 10000км', value: 10000},
]

export const users = [
	{
		name: 'Александрия',
		photo: ['./img/g1.jpg', './img/g2.jpg'],
		age: 23,
		distance: 50
	},
	{
		name: 'Олеся',
		photo: ['./img/g2.jpg', './img/g3.jpg'],
		age: 28,
		distance: 37
	},
	{
		name: 'Катя',
		photo: ['./img/g3.jpg','./img/g2.jpg'],
		age: 21,
		distance: 5
	},
	{
		name: 'Епивания',
		photo: ['./img/g4.jpg', './img/g2.jpg'],
		age: 19,
		distance: 500
	},
	{
		name: 'Милфа',
		photo: ['./img/g5.jpeg', './img/g2.jpg'],
		age: 35,
		distance: 45
	},
]

export const user = {
	name: 'Александр',
	birthDay: 1,
  birthMonth: {name: 'май.', value: 5},
  birthYear: 2004,
  targetAge: {name: '20лет', value: 20},
  searchGender: {name: '', value: 1},
  searchRadius: {name: 'В радиусе 100км', value: 100},
  file: null,
  fileTwo: null,
  fileThree: null,
  age: 18
}

export const messages = [
	{
		dialogId: 718,
		rightUser: {
			name: 'Nataly',
			age: 18,
			distance: 50,
			photo: './img/g3.jpg'
		},
		message: [
			{
				id: 20,
				dialog_id: 718,
				user_id: 1,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 19,
				dialog_id: 718,
				user_id: 1,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 18,
				dialog_id: 718,
				user_id: 2,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 17,
				dialog_id: 718,
				user_id: 1,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 16,
				dialog_id: 718,
				user_id: 2,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			}
		],
	},
	{
		dialogId: 719,
		rightUser: {
			name: 'Nataly2',
			age: 18,
			distance: 50,
			photo: './img/g3.jpg'
		},
		message: [
			{
				id: 21,
				dialog_id: 719,
				user_id: 1,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 22,
				dialog_id: 719,
				user_id: 3,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 23,
				dialog_id: 719,
				user_id: 3,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 24,
				dialog_id: 719,
				user_id: 1,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 25,
				dialog_id: 720,
				user_id: 3,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 0,
				created_at: "2022-08-09 18:56:09",
			}
		],
	},
	{
		dialogId: 720,
		rightUser: {
			name: 'Nataly3',
			age: 34,
			distance: 500,
			photo: './img/g3.jpg'
		},
		message: [
			{
				id: 26,
				dialog_id: 720,
				user_id: 4,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 27,
				dialog_id: 720,
				user_id: 1,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 28,
				dialog_id: 720,
				user_id: 1,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 29,
				dialog_id: 720,
				user_id: 4,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 30,
				dialog_id: 720,
				user_id: 1,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			}
		],
	},
	{
		dialogId: 721,
		rightUser: {
			name: 'Nataly4',
			age: 21,
			distance: 150,
			photo: './img/g3.jpg'
		},
		message: [
			{
				id: 31,
				dialog_id: 721,
				user_id: 5,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 32,
				dialog_id: 721,
				user_id: 1,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 33,
				dialog_id: 721,
				user_id: 5,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 34,
				dialog_id: 721,
				user_id: 1,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 35,
				dialog_id: 721,
				user_id: 5,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 0,
				created_at: "2022-08-09 18:56:09",
			}
		],
	},
	{
		dialogId: 722,
		rightUser: {
			name: 'Nataly5',
			age: 18,
			distance: 50,
			photo: './img/g3.jpg'
		},
		message: [
			{
				id: 36,
				dialog_id: 722,
				user_id: 1,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 37,
				dialog_id: 722,
				user_id: 1,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 38,
				dialog_id: 722,
				user_id: 6,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 0,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 39,
				dialog_id: 722,
				user_id: 6,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 0,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 40,
				dialog_id: 722,
				user_id: 6,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 0,
				created_at: "2022-08-09 18:56:09",
			}
		],
	},
	{
		dialogId: 723,
		rightUser: {
			name: 'Nataly6',
			age: 18,
			distance: 50,
			photo: './img/g3.jpg'
		},
		message: [
			{
				id: 41,
				dialog_id: 723,
				user_id: 1,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 42,
				dialog_id: 723,
				user_id: 7,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 43,
				dialog_id: 723,
				user_id: 7,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 44,
				dialog_id: 723,
				user_id: 1,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 1,
				created_at: "2022-08-09 18:56:09",
			},
			{
				id: 44,
				dialog_id: 723,
				user_id: 7,
				text: "fghgfhfdh eryhtrhtr erhtrhtr drgrehre drgrdhgr drgrt errere drgre gre",
				is_read: 0,
				created_at: "2022-08-09 18:56:09",
			}
		],
	},
]