<template>
  <div class="wrapper" :class="bgClass">
    <HeaderComponent @open="openNav($event)"></HeaderComponent>
    <div class="register__blue"></div>
    <RouterView v-if="isRouterAlive"/>
    <FooterComponent></FooterComponent>
    <MenuMobileComponent v-show="isShowNav" @close="closeNav($event)"></MenuMobileComponent>
  </div>
</template>

<script>
  import HeaderComponent from "./components/HeaderComponent.vue";
  import FooterComponent from "./components/FooterComponent.vue"
  import MenuMobileComponent from "./components/MenuMobileComponent.vue";
  export default {
  	components: {
		  FooterComponent,
      HeaderComponent,
      MenuMobileComponent
    },
    provide() {
  		return {
  			reload: this.reload
      }
    },
    data() {
  		return {
  			isShowNav: false,
        isRouterAlive: true
      }
    },
    computed: {
    	bgClass() {
		    return this.$route.meta.layout
	    }
    },
    methods: {
  		closeNav(x) {
  			this.isShowNav = x;
      },
	    openNav(x) {
		    this.isShowNav = x;
	    },
      reload() {
  			this.isRouterAlive = false;
  			this.$nextTick(function () {
				  this.isRouterAlive = true;
			  })
      }
    }
  }
</script>