import {defineStore} from 'pinia';
import fetchClient from '../api';

export const mainStore = defineStore('main', {

	state: () => ({
		success: '',
		users: {},
		fav: {},
		status: '',
		list: {}
	}),

	getters: {},
	actions: {
		getUsers() {
			return new Promise((resolve, reject) => {
				fetchClient().get('dating/dialog/match').then(response => {
					let data = response.data;
					if (data.error) {
						this.status = 'auth_error';
						return reject(data);
					}
					this.users = data.results;
					return resolve(data);
				}).catch(err => {
					this.status = 'auth_error';
					return reject(err);
				});
			});
		},
		like(id) {
			return new Promise((resolve, reject) => {

				fetchClient().get('dating/dialog/like', {params: id}).then(response => {
					let data = response.data;
					if (data.error) {
						this.status = 'auth_error';
						return reject(data);
					}
					return resolve(data);
				}).catch(err => {
					this.status = 'auth_error';
					return reject(err);
				});
			});
		},
		async disLike(id) {
			try {
				let response = await fetchClient().get('dating/dialog/dislike', {params: id});
				let data = response.data;
				if (data.error) {
					this.status = 'auth_error';
					return data;
				}
				return data;
			} catch (err) {
				this.status = 'auth_error';
				return err;
			}
		},
		async getFav() {

			return new Promise((resolve, reject) => {
				fetchClient().get('dating/dialog/fav').then(response => {
					let data = response.data;
					if (data.error) {
						this.status = 'error';
						return reject(data);
					}
					this.fav = data.results;
					return resolve(data);
				}).catch(err => {
					this.status = 'auth_error';
					return reject(err);
				});
			});
		},
		getDistance(data) {
			return new Promise((resolve, reject) => {

				fetchClient().get('dating/dialog/distance', {params: data}).then(response => {
					let data = response.data;
					if (data.error) {
						this.status = 'error';
						return reject(data);
					}
					return resolve(data);
				}).catch(err => {
					this.status = 'error';
					return reject(err);
				});
			});
		},
		getListMessage() {
			return new Promise((resolve, reject) => {
				fetchClient().get('dating/dialog/list').then(response => {
					let data = response.data;
					console.log(data);
					if (data.error) {
						this.status = 'error';
						return reject(data);
					}
					this.list = data;
					return resolve(data);
				}).catch(err => {
					this.status = 'error';
					return reject(err);
				});
			});
		},
		getUser(id) {
			return new Promise((resolve, reject) => {
				fetchClient().get(`dating/dialog/list?id=${id}`).then(response => {
					let data = response.data;
					if (data.error) {
						this.status = 'error';
						return reject(data);
					}
					this.list = data;
					return resolve(data);
				}).catch(err => {
					this.status = 'error';
					return reject(err);
				});
			});
		},
		getMessage(dialogId) {
			return new Promise((resolve, reject) => {
				fetchClient().get(`dating/dialog/messages?dialogId=${dialogId}`).then(response => {
					let data = response.data;

					if (data.err) {
						this.status = 'error';
						return reject(data);
					}

					return resolve(data);
				}).catch(err => {
					this.status = 'error';
					return reject(err);
				});
			})
		},
		markReadMessage(dialogId) {
			return new Promise((resolve, reject) => {
				fetchClient().get(`dating/dialog/mark-message?dialogId=${dialogId}`).then(response => {
					let data = response.data;

					if (data.err) {
						this.status = 'error';
						return reject(data);
					}

					return resolve(data);
				}).catch(err => {
					this.status = 'error';
					return reject(err);
				});
			})
		},
		pushMessage(id, data) {
			return new Promise((resolve, reject) => {
				fetchClient().post(`dating/dialog/push-message?dialogId=${id}`, data).then(response => {
					let data = response.data;
					if (data.err) {
						this.status = 'error';
						return reject(data);
					}
					return resolve(data);
				}).catch(err => {
					this.status = 'error';
					return reject(err);
				});
			});
		},
		deleteDialog(id) {
			return new Promise((resolve, reject) => {
				fetchClient().get(`dating/dialog/delete-dialog?dialogId=${id}`).then(response => {
					let data = response.data;
					if (data.err) {
						this.status = 'auth_error';
						return reject(data);
					}
					console.log(data);
					return resolve(data);
				}).catch(err => {
					this.status = 'auth_error';
					return reject(err);
				});
			});
		}
	}
})