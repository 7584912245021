import { defineStore } from 'pinia'
import fetchClient from '../api';

export const paymentStore = defineStore('payment', {
	state: () => ({
		success: '',
		status: '',
		data: '',
	}),

	actions: {
		getPaymentParams(pk='', email='', order='') {
			return new Promise((resolve, reject) => {
				fetchClient().get(`payment/params?pk=${pk}&email=${email}&order=${order}`).then(response => {
					let data = response.data
					if (data.error) {
						this.status = 'auth_error';
						return reject(data);
					}
					this.data = data;
					return resolve(data);
				}).catch(err => {
					this.status = 'auth_error';
					return reject(err);
				});
			})
		}
	}
})