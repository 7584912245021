import { defineStore } from "pinia";
import fetchClient from '../api';

export const serviceStore = defineStore('service', {

	state: () => ({
		status: '',
		rules: '',
		price: '',
		contact: '',
	}),

	actions: {
		getRules() {
			return new Promise((resolve, reject) => {
				fetchClient().get('service/rules').then(response => {
					let data = response.data;
					if (data.error) {
						this.status = 'error';
						return reject(data);
					}

					this.rules = data.content;
					return resolve(data);
				}).catch(err => {
					this.status = err;
					return reject(err)
				})
			})
		},
		getPrice() {
			return new Promise((resolve, reject) => {
				fetchClient().get('service/price').then(response => {
					let data = response.data;
					if (data.error) {
						this.status = 'error';
						return reject(data);
					}

					this.price = data.content;
					return resolve(data);
				}).catch(err => {
					this.status = err;
					return reject(err)
				})
			})
		},
		getContact() {
			return new Promise((resolve, reject) => {
				fetchClient().get('service/contact').then(response => {
					let data = response.data;
					if (data.error) {
						this.status = 'error';
						return reject(data);
					}

					this.contact = data.content;
					return resolve(data);
				}).catch(err => {
					this.status = err;
					return reject(err)
				})
			})
		}
	}
})