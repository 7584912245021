<template>
  <div class="container container__chat">
    <div class="message__header">
      <div class="message__header-content">
        <div class="message__title">Чат начат</div>
      </div>

      <div href="#" class="mes-button" @click="payments">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.2317 24H21.0748C2.94253 22.9569 0.36802 7.65231 0.00814289 2.98154C-0.048103 2.24931 0.188712 1.52471 0.666499 0.967131C1.14429 0.409551 1.82391 0.0646566 2.55589 0.00830862C2.62909 0.00277015 2.70261 8.65468e-07 2.77643 8.65468e-07H7.63938C8.009 -0.00035707 8.37021 0.110317 8.67623 0.317689C8.98224 0.52506 9.21896 0.819573 9.35572 1.16308L10.7583 4.61539C10.8934 4.95097 10.9269 5.31884 10.8547 5.67332C10.7825 6.02779 10.6078 6.35324 10.3523 6.60923L8.38682 8.59385C8.69259 10.3397 9.52771 11.9492 10.7789 13.2042C12.0301 14.4591 13.6369 15.2988 15.3814 15.6092L17.3838 13.6246C17.6435 13.3718 17.9719 13.2012 18.328 13.1339C18.6841 13.0665 19.0521 13.1056 19.3861 13.2462L22.865 14.64C23.2032 14.7811 23.4917 15.0198 23.6939 15.3255C23.896 15.6313 24.0025 15.9904 24 16.3569V21.2308C24 21.9652 23.7083 22.6696 23.1891 23.1889C22.67 23.7082 21.9659 24 21.2317 24ZM2.77643 1.84615C2.65525 1.84579 2.53519 1.86931 2.42309 1.91536C2.311 1.96141 2.20907 2.0291 2.12313 2.11456C2.03718 2.20002 1.96891 2.30157 1.9222 2.41343C1.87549 2.52528 1.85126 2.64524 1.8509 2.76646C1.8509 2.79231 1.85182 2.81785 1.85367 2.84308C2.27814 8.30769 5.00029 21.2308 21.1763 22.1538C21.4205 22.1685 21.6606 22.0855 21.8437 21.9231C22.0267 21.7608 22.1379 21.5323 22.1526 21.288L22.1544 21.2308V16.3569L18.6756 14.9631L16.0273 17.5938L15.5844 17.5385C7.55634 16.5323 6.46748 8.50154 6.46748 8.41846L6.41211 7.97539L9.03275 5.32616L7.64861 1.84615H2.77643Z"></path>
        </svg>
        <span>Открыть профиль</span>
      </div>

    </div>
    <div class="message__container">
      <div class="message__container-left messages-window-list ">

        <div class="message-ava">
          <div class="message-ava__img" :class="girl.class" id="js-image"></div>
          <div class="message-ava__name">
            <span class="name-bot">{{ girl.name }}</span> / <span class="city">{{ girl.city }}</span>
          </div>
        </div>
        <div class="message-time" id="js-time">{{ timers }}</div>
        <div class="message-ava__username">
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.6754 11.9793L20.4128 7.72466L24.9756 2.7157H42.9869L48.4803 8.75059H57.9962C61.2983 8.75059 64 11.4663 64 14.7855V50.9948C64 51.1457 63.97 51.2966 63.94 51.4776L57.9962 45.4729V14.7855H45.8386L40.3452 8.75059H27.6173L24.6754 11.9793ZM59.4672 64L52.5028 57.0297H9.96623C6.66416 57.0297 3.96248 54.314 3.96248 50.9948V14.7855C3.96248 13.0052 4.77298 11.4059 6.00375 10.2895L0 4.2546L4.26266 0L63.6998 59.7454L59.4672 64ZM25.546 29.9331C25.2158 30.8685 24.9756 31.834 24.9756 32.8901C24.9756 37.8991 28.9981 41.9425 33.9812 41.9425C35.0319 41.9425 35.9925 41.7011 36.9231 41.3692L25.546 29.9331ZM46.4991 50.9948L41.4559 45.9255C39.2645 47.223 36.7129 47.9774 33.9812 47.9774C25.6961 47.9774 18.9719 41.2183 18.9719 32.8901C18.9719 30.1443 19.7223 27.5794 21.0131 25.3767L10.4765 14.7855H9.96623V50.9948H46.4991ZM42.4165 29.8124L48.6304 36.0585C48.8705 35.0325 48.9906 33.9764 48.9906 32.8901C48.9906 24.562 42.2664 17.8029 33.9812 17.8029C32.9006 17.8029 31.8499 17.9236 30.7992 18.165L37.0131 24.4111C39.5347 25.3164 41.5159 27.3079 42.4165 29.8124Z" fill="#FFFDFD"></path>
          </svg>
        </div>
      </div>
      <div class="message__container-right">
        <vueCustomScrollbar
          :settings="settings"
          class="dialog__wrapp"
          id="js-dialog"
          ref="scroll"
        >
          <div
            class="message__wrapp"
            v-for="(item, index) in dialogs"
            :key="index"
          >
            <div class="message__username">{{ item.name }}</div>
            <div class="message__text">{{ item.text }}</div>
          </div>

        </vueCustomScrollbar>
        <div class="message-animation" :class="isAnimation ? 'active' : 'disabled'">
          <span id="js-animation-name" class="loading">{{ girl.name }} печатает . . .</span>
          <span class="qwe" id="qwe"></span>
        </div>
        <div class="message__controls">
          <div class="message__controls-form">
            <div class="message__controls-textarea">
              <input
                class="message__controls-input"
                name="message" id="messageOut"
                placeholder="Твое сообщение..."
                v-model="message"
              >
              <button class="circle message__controls-btn" @click="addMessage">
                <svg viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.25 0C14.4337 2.62526e-05 14.611 0.0730899 14.7483 0.205333C14.8856 0.337576 14.9733 0.5198 14.9948 0.717444L15 0.812507V6.0938C15.0001 6.82105 14.7429 7.52069 14.2815 8.04886C13.82 8.57704 13.1893 8.89362 12.519 8.93352L12.375 8.93758H2.56125L5.03025 11.6132C5.15938 11.7531 5.23696 11.9392 5.24842 12.1367C5.25988 12.3341 5.20444 12.5293 5.0925 12.6857L5.03025 12.762C4.90111 12.9019 4.72928 12.986 4.54701 12.9984C4.36474 13.0108 4.18455 12.9508 4.04025 12.8295L3.96975 12.762L0.21975 8.69951C0.193478 8.67115 0.169163 8.64073 0.147 8.60851L0.09375 8.51914L0.0532501 8.42651L0.027 8.3412L0.00824994 8.24613L0.00299996 8.19738L0 8.12507L0.00225002 8.06413L0.015 7.96176L0.0375 7.87157L0.0705001 7.78138L0.1095 7.70176L0.1575 7.627L0.21975 7.55063L3.96975 3.48809C4.10472 3.34237 4.28583 3.25777 4.47629 3.25147C4.66675 3.24517 4.85228 3.31764 4.99521 3.45417C5.13813 3.5907 5.22773 3.78104 5.2458 3.98655C5.26387 4.19205 5.20906 4.39729 5.0925 4.5606L5.03025 4.63698L2.56125 7.31256H12.375C12.6545 7.31257 12.9241 7.19982 13.1311 6.9963C13.3381 6.79277 13.4677 6.51304 13.4948 6.21162L13.5 6.0938V0.812507C13.5 0.597017 13.579 0.390353 13.7197 0.237978C13.8603 0.0856033 14.0511 0 14.25 0Z" fill="white"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import vueCustomScrollbar from 'vue-custom-scrollbar/src/vue-scrollbar.vue';
	import "vue-custom-scrollbar/dist/vueScrollbar.css";
	import { paymentStore } from "../store/store_payment";

	export default {
		name: "Chat",
		components: {
			vueCustomScrollbar
		},
    data() {
	    const payment = paymentStore();
			return {
				payment,
				settings: {
					suppressScrollY: false,
					suppressScrollX: false,
					wheelPropagation: false,
					minScrollbarLength: 47,
					maxScrollbarLength: 47,
					handlers: [ 'click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
					swicher: false,
				},
				girlList: [
					{
						id: 1,
						class: 'girl--one',
						name: 'Алена',
            city: 'Новосибирск',
						phrase: [
							'В такую жару так хочется влаги! Я мокну только при мысли о тебе!',
							'Хочешь провести время вместе? Потому что я просто мечтаю!',
							'Сейчас подзаряжу телефон и смогу снять для тебя пару видео! Надеюсь, ты ответишь тем же!',
						]
					},
					{
						id: 1,
						class: 'girl--two',
						name: 'Маринчик',
						city: 'Архангельск',
						phrase: [
							'Привет) Я уже устала развлекать себя мыслями о тебе, хочу чтобы ты уже просто взял и овладел мной! ',
							'Ты думал о том, как я сползаю поцелуями по твоему телу сверху вниз? ',
							'Я очень хочу уже просто взять и сделать это! А ты? Не стесняйся!',
						]
					},
					{
						id: 1,
						class: 'girl--three',
						name: 'Леночка',
						city: 'Брянск',
						phrase: [
							'Хей! Сегодня иду с девочками в бар, а когда вернусь планирую поразвлечься с тобой!',
							'Что ты об этом думаешь? Сегодня вход открыт в моём личном баре, скажем так...',
							'Наберу тебе по возвращении, а пока держи пару фото для разогрева!',
						]
					},
					{
						id: 1,
						class: 'girl--four',
						name: 'Настя :)',
						city: 'Казань',
						phrase: [
							'Привет) Это удивительно, но меня сильно заводит общение с незнакомцем!',
							'Сама не верю, что пишу это, но я постоянно думаю о том, как ты прижимаешь меня к стене и срываешь одежду!',
							'Смотри, сейчас скину фотки и ты поймешь, насколько я возбуждена.. Сможешь усмирить этот огонь? Только не молчи!',
						]
					},
					{
						id: 1,
						class: 'girl--five',
						name: 'Людмилка',
						city: 'Москва',
						phrase: [
							'Привет! Как ты? Сегодня было столько дел, а я не могу думать ни о чем. Только о том, как настоящий мужик хватает меня за волосы и…',
							'Свободен сегодня вечером? ',
							'Прошу, не молчи! Я вся горю от нетерпения!',
						]
					},
					{
						id: 1,
						class: 'girl--six',
						name: 'Полинка',
						city: 'Оренбург',
						phrase: [
							'Привет, еще не знакомец)) Я купила себе новое белье!!! Оно такое красивое..',
							'Я не хвастунишка, но нужно, чтобы со стороны оценили) Поможешь?',
							'Готов получить небольшой анонс в виде фото??',
						]
					},
				],
        girl: {
					name: '',
          city: '',
          class: '',
          phrase: [],
        },
        dialogs: [],
        message: '',
        isAnimation: true,
				timers: '40 : 00',
        pk: '',
        email: '',
      }
    },
    created() {
			this.showRandomGirl();
	    this.pk = this.$route.query.pk;
	    this.email = this.$route.query.email;
    },
		mounted() {
			this.messageTime();
			this.addBotMessage(0, 4000);
			this.addBotMessage(1, 10000);
			this.addBotMessage(2, 22000);
    },
		methods: {
			showRandomGirl() {
				let rand = Math.floor(Math.random()*this.girlList.length);
				this.girl = this.girlList[rand];
      },
	    messageTime() {
        let times = 39;
        // let blockTime = document.querySelector('#js-time');
        let timer = setInterval(function () {
          if(times < 0) {
            clearInterval(timer);
            this.payments();
          } else {
	          this.timers = times < 10 ? `0${times} : 00 ` : `${times} : 00`;
          }
          --times;
        }.bind(this), 1000);
      },
      addBotMessage(index, time) {
				this.isAnimation = true;
				setTimeout(function () {
					this.isAnimation = false;
					setTimeout(function () {
						this.isAnimation = true;
					}.bind(this), 2000)
          let dialog = {
						text: this.girl.phrase[index],
						name: this.girl.name,
          }
          this.dialogs.unshift(dialog);
				}.bind(this), time)
      },
      addMessage() {
	      let dialog = {
		      text: this.message,
		      name: 'Вы',
	      }
        this.dialogs.unshift(dialog);
        this.message = '';
      },
			payments() {
				this.payment.getPaymentParams(this.pk, this.email).then((data) => {
					this.required = data.redirect;
					window.location.href = this.required;
				})
			},
    }
	}
</script>

<style scoped lang="scss">
  @media (max-width: 900px) {
    .message__container {
      align-items: center;
    }
  }
</style>