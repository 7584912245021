<template>
  <div class="previews">
    <div class="previews__title title">Нас много! Присоединяйся</div>
    <div class="previews__list">
      <div class="previews__item" v-for="(item, index) in previews" :key="index">
        <div class="previews__item-photo" :class="item.class"></div>
        <div class="previews__item-name">{{ item.name }}</div>
        <div class="previews__item-geo">{{ item.geo }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
  	data() {
  		return {
  			previews: [
          {
          	name: 'Диман, 32',
            geo: 'Москва, Россия',
            class: 'prev1'
          },
				  {
					  name: 'Маша, 21',
					  geo: 'Саратов, Россия',
					  class: 'prev2'
				  },
				  {
					  name: 'Гульнур, 18',
					  geo: 'Нур-Султан, Казахстан',
					  class: 'prev3'
				  },
				  {
					  name: 'Мишган, 28',
					  geo: 'Гомель, Беларусь',
					  class: 'prev4'
				  },
				  {
					  name: 'Фарида, 24',
					  geo: 'Стамбул, Турция',
					  class: 'prev5'
				  },
				  {
					  name: 'Дон Квон, 30',
					  geo: 'Неизвестно, Корея',
					  class: 'prev6'
				  }
        ]
      }
    }
  }
</script>

<style lang="scss">
  
</style>