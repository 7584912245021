<template>
  <div class="container container__main">
    <div class="content__wrapper">
      <div class="card__nodonwlad" v-if="isLoading">
        <div class="card__nodonwlad-animation">
          <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37 5C30.671 5 24.4841 6.87677 19.2218 10.393C13.9594 13.9092 9.85787 18.9069 7.43587 24.7541C5.01386 30.6014 4.38016 37.0355 5.61489 43.2429C6.84961 49.4503 9.89732 55.1521 14.3726 59.6274C18.8479 64.1027 24.5497 67.1504 30.7571 68.3851C36.9645 69.6198 43.3986 68.9861 49.2459 66.5641C55.0931 64.1421 60.0908 60.0406 63.607 54.7782C67.1232 49.5159 69 43.329 69 37" stroke="url(#paint0_linear_19_2274)" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
              <linearGradient id="paint0_linear_19_2274" x1="37" y1="5" x2="46" y2="57" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF4E4E"/>
                <stop offset="0.489142" stop-color="white"/>
                <stop offset="0.625" stop-color="white"/>
                <stop offset="0.989694" stop-color="#85E8FF"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div class="card__nodonwlad-title">Поиск подходящих анкет</div>
        <div class="card__nodonwlad-text">Подбираем наиболее подходящих вам партнёров, подождите несколько секунд</div>
      </div>
      <div class="fake__img" v-else>
        <div class="card__nodonwlad-blur">

        </div>
        <div class="card__nodonwlad-title">Найдено!</div>
        <div class="card__nodonwlad-text">Мы нашли 23 подходящих для вас человек</div>

      </div>
    </div>
    <router-link to="/" class="show__block" v-show="!isLoading">
      <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M55.621 11.681C54.7366 9.39518 53.4613 7.32377 51.8667 5.58276C50.2709 3.83655 48.3893 2.44886 46.3244 1.49516C44.1832 0.502297 41.8867 -0.00590519 39.5681 5.1768e-05C36.3153 5.1768e-05 33.1416 0.994333 30.3837 2.87242C29.7239 3.32169 29.0971 3.81515 28.5033 4.3528C27.9094 3.81515 27.2826 3.32169 26.6228 2.87242C23.8649 0.994333 20.6913 5.1768e-05 17.4385 5.1768e-05C15.0962 5.1768e-05 12.8265 0.500875 10.6821 1.49516C8.61036 2.45261 6.74313 3.82988 5.13982 5.58276C3.54309 7.32181 2.26758 9.3937 1.38558 11.681C0.468456 14.0599 0 16.5861 0 19.186C0 21.6386 0.448663 24.1942 1.33939 26.7941C2.08496 28.9668 3.15383 31.2205 4.51961 33.4963C6.68375 37.0978 9.65944 40.854 13.3543 44.6617C19.4772 50.9736 25.5408 55.3337 25.7981 55.5104L27.3618 56.6299C28.0546 57.1234 28.9453 57.1234 29.6381 56.6299L31.2018 55.5104C31.4592 55.3263 37.5161 50.9736 43.6456 44.6617C47.3405 40.854 50.3162 37.0978 52.4803 33.4963C53.8461 31.2205 54.9216 28.9668 55.6605 26.7941C56.5513 24.1942 56.9999 21.6386 56.9999 19.186C57.0065 16.5861 56.5381 14.0599 55.621 11.681Z" fill="white"/>
      </svg>
      <span>Показать</span>
    </router-link>
  </div>
</template>

<script>
	export default {
		name: "Fake",
    data() {
			return {
				isLoading: true,
      }
    },
    mounted() {
			this.hideLoading()
    },
		methods: {
			hideLoading() {
				setTimeout(() => {
					this.isLoading = false;
        }, 5000)
      }
    }
	}
</script>

<style scoped>
  .fake__img {
    background-image: url("img/fake.jpg");
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
    border-radius: 50%;
    color: #fff;
  }

  .card__nodonwlad-blur{
    background: rgba(255,255,255,.1);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .card__nodonwlad-title {
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  .card__nodonwlad-title,
  .card__nodonwlad-text {
    z-index: 11;
  }

  .show__block {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    grid-gap: 10px;
    width: 329px;
    height: 140px;
    bottom: -35px;
    background: #FF4E4E;
    border-radius: 90px;
    z-index: 100;
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    color: #FFFFFF;
  }
</style>