import { defineStore } from 'pinia'
import fetchClient from '../api';

export const useStore = defineStore('register', {

	state: () => ({
		success: '',
		token: localStorage.getItem('token') || '',
		status: '',
		statusCheck: '',
		username: localStorage.getItem('name') || '',
		email: localStorage.getItem('email') || '',
		password: '',
		unsub: localStorage.getItem('isUnsub') || '',
		countNewMessage: 0,
	}),

	getters: {
		isAuth: state => {
			return state.token && state.token.length
		},
		isUnsub: state => {
			// return state.unsub
			return state.unsub === true || state.unsub === "true";
		},
		authStatus: state => state.status,
	},
	actions: {
		login(data) {
			return new Promise((resolve, reject) => {
				this.status = 'auth_request';

				fetchClient().post('user/auth', data).then(response => {
					let data = response.data;
					if (data.error) {
						this.status = 'auth_error';
						return reject(data);
					}
					const token = data.token;
					const username = data.username;
					const email = data.email;
					const isUnsub = data.isAccess

					localStorage.setItem('token', token);
					localStorage.setItem('name', username);
					localStorage.setItem('email', email);
					localStorage.setItem('isUnsub', isUnsub);

					this.token = token;
					this.status = 'auth_success';
					this.username = username;
					this.email = email;
					this.unsub = isUnsub;

					return resolve(data);
				}).catch(err => {
					this.status = 'auth_error';
					localStorage.removeItem('token');
					return reject(err);
				});
			});
		},
		register(pk) {
			return new Promise((resolve, reject) => {
				this.status = 'auth_request';
				fetchClient().get(`user/pre-order?pk=${pk}`).then(response => {
					let data = response.data;
					if (data.error) {
						this.status = 'auth_error';
						return reject(data);
					}
					const token = data.token;
					const username = data.username;
					const email = data.email;
					const isUnsub = data.isAccess
					localStorage.setItem('token', token);
					localStorage.setItem('name', username);
					localStorage.setItem('email', email);
					localStorage.setItem('isUnsub', isUnsub);

					this.token = token;
					this.status = 'auth_success';
					this.username = username;
					this.unsub = isUnsub;

					return resolve(data);
				}).catch(err => {
					this.status = 'auth_error';
					return reject(err);
				});
			});
		},
		logout() {
			return new Promise((resolve) => {
				this.status = 'auth_logout';
				this.token = '';
				this.username = '';
				localStorage.removeItem('token');
				localStorage.removeItem('name');
				localStorage.removeItem('email');
				localStorage.removeItem('gender');
				localStorage.removeItem('isUnsub');
				delete fetchClient().defaults.headers.common['Authorization'];
				return resolve();
			});
		},
		getOk() {
			return new Promise((resolve, reject) => {
				fetchClient().get('user/ok').then(response => {
					let data = response.data;
					if (data.error) {
						this.status = 'ok_error';
						return reject(data);
					}
					this.email = data.email;
					this.password = data.password;
					return resolve(data);
				}).catch(err => {
					this.status = 'ok_error';
					return reject(err);
				});
			});
		},
		getCheck(data) {
			return new Promise((resolve, reject) => {
				fetchClient().post('user/check', data).then(response => {
					let data = response.data;
					if (data.error) {
						this.status = 'check_error';
						return reject(data);
					}

					return resolve(data);
				}).catch(err => {
					this.statusCheck = 'check_error';
					return reject(err);
				});
			})
		},
		unsubscribe(email) {
			return new Promise((resolve, reject) => {
				fetchClient().get(`user/unsubscribe?email=${email}`).then(response => {
					let data = response.data;
					if (data.error) {
						this.status = 'error';
						return reject(data);
					}

					return resolve(data);
				}).catch(err => {
					this.statusCheck = 'error';
					return reject(err);
				});
			});
		},
		async getCountNewMessages() {
			try {
				let response = await fetchClient().get('dating/dialog/message-count');
				let data = response.data;
				this.countNewMessage = data.count;
				if (data.error) {
					this.status = 'auth_error';
					return data;
				}
				return data;
			} catch (err) {
				this.status = 'auth_error';
				return err;
			}
		},
	},
})