<template>
  <form action="" class="form">
    <div class="profile__form-row">
      <div class="profile__form-col сol--gender">
        <ToggleGenderComponent @genders="setGender" :genderDefault="gender"></ToggleGenderComponent>
      </div>
    </div>

    <div class="profile__form-row">

      <div class="profile__form-col">
        <label class="profile__label" for="name">Имя</label>
        <input type="text" id="name" v-model="username" placeholder="Ваше имя" class="form__email-input">
      </div>

      <div class="profile__form-col">
        <div class="birthday profile__label">Дата рождения</div>
        <div class="age">
          <v-select
            class="form__select-age"
            label="name"
            :options="day"
            v-model="birthDay"
            :closeOnSelect="true"
          ></v-select>
          <v-select
            class="form__select-age"
            label="name"
            :options="month"
            v-model="birthMonth"
          ></v-select>
          <v-select
            class="form__select-age"
            :options="years"
            v-model="birthYear"
          ></v-select>
        </div>
      </div>
    </div>

    <div class="profile__form-row">
      <div class="profile__form-col">
        <div class="profile__label">Возраст цели для поиска</div>
        <v-select
          class="form__email-input profile__select"
          label="name"
          :options="age"
          v-model="targetAge"
          @input="setSelected"
        ></v-select>
      </div>
      <div class="profile__form-col">
        <div class="profile__label">Кого ты ищешь?</div>
        <v-select
          class="form__email-input profile__select"
          label="name"
          :options="genders"
          v-model="searchGender"
        ></v-select>
      </div>
    </div>

    <div class="profile__form-row">
      <div class="profile__form-col">
        <label class="profile__label" for="name">Расстояние для поиска</label>
        <v-select
          class="form__email-input profile__select"
          label="name"
          :options="radius"
          v-model="searchRadius"
        ></v-select>
      </div>
      <div class="profile__form-col">
        <label class="profile__label" for="fileOne">Загрузи свое фото</label>
        <input
          type="file"
          ref="file"
          id="fileOne"
          accept="image/*"
          @change="onChangeUpload()"
          class="input__hidden">
        <label class="label__file" for="fileOne">
          <span class="label-info">{{ fileNameOne }}</span>
          <span class="label-img">Обзор...</span>
        </label>
      </div>
    </div>

    <div class="profile__form-row">
      <div class="profile__form-col w100">
        <div class="profile__label">Твой город</div>
        <v-select
          class="form__email-input profile__select"
          label="title"
          :options="countries"
          v-model="country"
        ></v-select>
      </div>
    </div>

    <div class="profile__form-row">
      <div class="profile__form-col">
        <div class="profile__label not--necessary">Загрузи второе фото</div>
        <input
          type="file"
          ref="file2"
          id="fileTwo"
          accept="image/*"
          @change="onChangeUploadTwo()"
          class="input__hidden">
        <label class="label__file" for="fileTwo">
          <span class="label-info">{{ fileNameTwo }}</span>
          <span class="label-img">Обзор...</span>
        </label>
      </div>
      <div class="profile__form-col">
        <div class="profile__label not--necessary">Загрузи свое третье фото</div>
        <input
          type="file"
          ref="file3"
          id="fileThree"
          accept="image/*"
          @change="onChangeUploadThree()"
          class="input__hidden">
        <label class="label__file" for="fileTwo">
          <span class="label-info">{{ fileNameThree }}</span>
          <span class="label-img">Обзор...</span>
        </label>

      </div>
    </div>

    <div class="profile__button">
      <div class="profile__button-delete" v-show="isDelete" @click="deleteUser">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.17387 4.99929L9.75374 1.42723C9.91051 1.27044 9.99858 1.05779 9.99858 0.836054C9.99858 0.614319 9.91051 0.401665 9.75374 0.244875C9.59697 0.0880841 9.38435 0 9.16264 0C8.94094 0 8.72832 0.0880841 8.57155 0.244875L5 3.82526L1.42845 0.244875C1.27168 0.0880841 1.05906 -1.65206e-09 0.837356 0C0.615652 1.65206e-09 0.403029 0.0880841 0.246261 0.244875C0.0894923 0.401665 0.0014208 0.614319 0.00142079 0.836054C0.00142079 1.05779 0.0894923 1.27044 0.246261 1.42723L3.82613 4.99929L0.246261 8.57135C0.168229 8.64875 0.106294 8.74084 0.0640274 8.84231C0.021761 8.94377 0 9.05261 0 9.16253C0 9.27244 0.021761 9.38128 0.0640274 9.48274C0.106294 9.58421 0.168229 9.6763 0.246261 9.7537C0.323655 9.83175 0.415733 9.89369 0.517185 9.93596C0.618636 9.97824 0.727452 10 0.837356 10C0.94726 10 1.05608 9.97824 1.15753 9.93596C1.25898 9.89369 1.35106 9.83175 1.42845 9.7537L5 6.17332L8.57155 9.7537C8.64894 9.83175 8.74102 9.89369 8.84247 9.93596C8.94392 9.97824 9.05274 10 9.16264 10C9.27255 10 9.38136 9.97824 9.48281 9.93596C9.58427 9.89369 9.67634 9.83175 9.75374 9.7537C9.83177 9.6763 9.89371 9.58421 9.93597 9.48274C9.97824 9.38128 10 9.27244 10 9.16253C10 9.05261 9.97824 8.94377 9.93597 8.84231C9.89371 8.74084 9.83177 8.64875 9.75374 8.57135L6.17387 4.99929Z" fill="#FF4E4E"/>
        </svg>
        <span>Удалить странницу</span>
      </div>
      <div class="profile__button-container">
        <div class="profile__button-description">Поля отмеченные * обязательны для заполнения</div>
        <button type="button" id="reg-button" class="button button--register button__save" @click.prevent="updateUser">
          <span>Сохранить и продолжить</span>
        </button>
      </div>
    </div>

    <div class="error__block" v-show="isError">
      <div class="error__block-icon">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M31.5008 8.85333L23.1765 0.533333C22.8386 0.195555 22.3761 0 21.9136 0H10.103C9.64058 0 9.17811 0.195555 8.85795 0.515556L0.515824 8.85333C0.195657 9.17333 0 9.63556 0 10.0978V21.8844C0 22.3644 0.195657 22.8089 0.515824 23.1467L8.84016 31.4667C9.17811 31.8044 9.64058 32 10.103 32H21.8958C22.3761 32 22.8208 31.8044 23.1587 31.4844L31.4831 23.1644C31.6487 22.9989 31.7797 22.802 31.8682 22.5853C31.9568 22.3685 32.0012 22.1363 31.9989 21.9022V10.0978C32.0167 9.63556 31.821 9.17333 31.5008 8.85333ZM28.4593 21.1556L21.1666 28.4444H10.8501L3.55741 21.1556V10.8444L10.8501 3.55556H21.1666L28.4593 10.8444V21.1556Z" fill="white"/>
          <path d="M16.0083 24.8889C16.9907 24.8889 17.787 24.093 17.787 23.1111C17.787 22.1293 16.9907 21.3333 16.0083 21.3333C15.026 21.3333 14.2296 22.1293 14.2296 23.1111C14.2296 24.093 15.026 24.8889 16.0083 24.8889Z" fill="white"/>
          <path d="M16.0083 7.11111C15.03 7.11111 14.2296 7.91111 14.2296 8.88889V17.7778C14.2296 18.7556 15.03 19.5556 16.0083 19.5556C16.9866 19.5556 17.787 18.7556 17.787 17.7778V8.88889C17.787 7.91111 16.9866 7.11111 16.0083 7.11111Z" fill="white"/>
        </svg>
      </div>
      <div class="error__block-text">
        <span>Ошибка: </span> {{ errorText }}
      </div>
    </div>
  </form>
</template>

<script>
  import {day, month, years, age, genders, radius} from "../json/data";
  import { profile } from "../store/store_profile";
  import {useStore} from '../store/store_pinia';
  import ToggleGenderComponent from "./ToggleGenderComponent.vue";

  export default {
  	components: {
		  ToggleGenderComponent
	  },
  	data() {
		  const store = profile();
		  const store2 = useStore();
  		return {
  			store,
        store2,
        month: month,
        day: day,
        years: years,
        age: age,
        genders,
        radius,
			  countries: [],
			  user: {
				  isMainPhotoModerated: '',
				  profileParams: {
					  searchAge: '',
					  birthday: '',
            city_id: 1,
            gender: '',
				  },
				  userPhoto: [
					  {path: ''}
				  ]
			  },
        userPhoto: '',
        country: {},
			  fileNameOne: '',
        mainPhoto: '',
			  fileNameTwo: '',
			  additionalPhotoFirst: '',
			  fileNameThree: '',
			  additionalPhotoSecond: '',
			  searchGender: {name: 'Девушка', value: 2},
			  birthDay: '',
			  birthMonth: null,
			  birthYear: '',
        username: '',
			  targetAge: null,
			  searchRadius: null,
        cityId: 1,
			  gender: null,
        isError: false,
        errorText: '',
			  isMainPhotoModerated: false,
			  countPhotoModerated: null,
      }
    },
    props: {
      isDelete: {
        type: Boolean,
        default: false
      },
    },
	  created() {
      this.getUser();
		  this.getCountries();
	  },
    mounted() {
    },
	  methods: {
		  setGender(value) {
			  this.gender = value;
		  },
  		emitMainPhoto() {
        if (this.countPhotoModerated > 0 && this.user.userPhoto.length === 0) {
	        this.fileNameOne = 'Ваше фото на модерации!';
        }

  			if (this.user.userPhoto.length > 0) {
				  this.userPhoto = this.user.userPhoto[0].path;

				  if (this.userPhoto) {
					  this.fileNameOne = 'img1';
          }

				  if (this.user.userPhoto[1]) {
					  this.fileNameTwo = 'img2';
				  }

				  if (this.user.userPhoto[2]) {
					  this.fileNameThree = 'img3';
				  }

				  this.$emit('imgChange', this.userPhoto )
        }
      },
		  getForm() {
        let arr =  this.user.profileParams.birthday.split('-');
			  let searchGender = this.user.profileParams.searchGender;
			  let radius = this.user.profileParams.searchRadius;
			  let age = this.user.profileParams.searchAge;

			  this.birthDay = Number(arr[2]);
			  this.birthMonth = this.month.filter(item => {
				  return item.value === Number(arr[1])
			  })[0];
			  this.birthYear = arr[0];
			  this.searchRadius = {name: `${radius}км`, value: radius};
			  this.targetAge = {name: `${age} лет`, value: age};

			  this.searchGender = searchGender === '2' ? {name: 'Девушка', value: 2} : {name: 'Парень', value: 1}
		  },
	    onChangeUpload() {
	    	this.fileNameOne = this.$refs.file.files[0].name;
		    this.mainPhoto = this.$refs.file.files[0];

		    if (this.mainPhoto.size >= 3145728) {
			    this.errorText = 'Размер фото не должен превышать 3мб!';
			    this.isError = true;
			    return false;
        } else {
			    this.errorText = '';
			    this.isError = false;
        }

		    let reader  = new FileReader();

		    reader.addEventListener("load", function () {
			    this.userPhoto = reader.result;
			    this.$emit('imgChange', this.userPhoto )
		    }.bind(this), false);

		    if( this.mainPhoto ){
			    if ( /\.(jpe?g|png)$/i.test( this.mainPhoto.name ) ) {
				    reader.readAsDataURL( this.mainPhoto );
			    } else {
				    this.errorText = 'Этот формат фото не поддерживается. Загрузите "png", "jpg", "gif"';
				    this.isError = true;
				    return false;
          }
		    }
	    },
	    onChangeUploadTwo() {
		    this.fileNameTwo = this.$refs.file2.files[0].name;
		    this.additionalPhotoFirst = this.$refs.file2.files[0];

		    if (this.additionalPhotoFirst.size >= 3145728) {
			    this.errorText = 'Размер фото не должен превышать 3мб!';
			    this.isError = true;
			    return false;
		    } else {
			    this.errorText = '';
			    this.isError = false;
		    }

		    let reader  = new FileReader();

		    if( this.additionalPhotoFirst ){
			    if ( /\.(jpe?g|png)$/i.test( this.additionalPhotoFirst.name ) ) {
				   reader.readAsDataURL( this.additionalPhotoFirst );
			    }
		    }
	    },
		  onChangeUploadThree() {
		    this.fileNameThree = this.$refs.file3.files[0].name;
		    this.additionalPhotoSecond = this.$refs.file3.files[0];

			  if (this.additionalPhotoSecond.size >= 3145728) {
				  this.errorText = 'Размер фото не должен превышать 3мб!';
				  this.isError = true;
				  return false;
			  } else {
				  this.errorText = '';
				  this.isError = false;
			  }

			  let reader  = new FileReader();

			  if( this.mainPhoto ){
				  if ( /\.(jpe?g|png)$/i.test( this.additionalPhotoSecond.name ) ) {
					  reader.readAsDataURL( this.additionalPhotoSecond );
				  } else {
					  this.errorText = 'Неверный формат фото';
					  this.isError = true;
					  return;
          }
			  }
	    },
	    getCountries() {
		    this.store.getCountries().then((data) => {
			    this.countries = data.results;
		    })
	    },
	    getCountry() {
		    this.store.getCountry(this.user.profileParams.city_id).then((data) => {
			    this.country = data.results;
		    })
      },
		  logout() {
			  this.store2.logout().then((data) => {
				  if (!this.store2.isAuth) {
					  this.$router.push('/');
				  }
			  })
		  },
      deleteUser() {
		  	this.store.deleteUser().then((data) => {
		  		if(data.status) {
		  			this.logout();
          }
        })
      },
      updateUser() {
	      if (this.gender === null) {
		      this.errorText = 'Укажите свой пол';
		      this.isError = true;
		      return;
	      }

	      if (this.username === '') {
	      	this.errorText = 'Укажите ваше имя';
	      	this.isError = true;
	      	return;
        }

	      if (this.birthDay === '' || this.birthMonth === null || this.birthYear === '') {
		      this.errorText = 'Укажите ваш возраст';
		      this.isError = true;
		      return;
	      }

	      if (this.targetAge === null) {
		      this.errorText = 'Укажите возраст поиска друга';
		      this.isError = true;
		      return;
	      }

	      if (this.searchRadius === null) {
		      this.errorText = 'Укажите радиус поиска';
		      this.isError = true;
		      return;
	      }

	      if (this.userPhoto === '' && !this.isMainPhotoModerated) {
		      this.errorText = 'Установите фотографию';
		      this.isError = true;
		      return;
	      }

	      localStorage.setItem('name', this.username);

	    	const formData = new FormData();
	      formData.append( 'birthDay', this.birthDay);
	      formData.append( 'birthMonth', this.birthMonth.value);
	      formData.append( 'birthYear', this.birthYear);
	      formData.append('searchGender', this.searchGender.value);
	      formData.append('targetAge', this.targetAge.value);
	      formData.append('searchRadius', this.searchRadius.value);
	      formData.append('name', this.username);
	      formData.append('cityId', this.country.id);
	      formData.append('ProfileForm[mainPhoto]', this.mainPhoto);
	      formData.append('ProfileForm[additionalPhotoFirst]', this.additionalPhotoFirst);
	      formData.append('ProfileForm[additionalPhotoSecond]', this.additionalPhotoSecond);
	      formData.append('gender', this.gender);

	      this.store.updateUser(formData).then((data) => {
		      this.errorText = '';
		      this.isError = false;
		      if(data.success) {
			      this.$router.push('/match');
		      } else {
			      this.errorText = data.errors[0];
			      this.isError = true;
          }
        }).catch((error) => {
		      if(error.toJSON().status === 413) {
			      this.errorText = 'Вес фотографии не должен быть больше 3мб';
			      this.isError = true;
			      return false;
          }
        })
      },
		  getUser() {
			  this.store.getUser().then((data) => {
			  	if (data.results.profileParams !== null) {
					  this.user = data.results;
					  this.gender = data.results.profileParams.gender;
					  this.getForm();
				  } else {
					  this.gender = localStorage.getItem('gender') !== null ? localStorage.getItem('gender') : null;
          }
				  this.username = localStorage.getItem('name');
			  	this.isMainPhotoModerated = data.results.isMainPhotoModerated;
			  	this.countPhotoModerated = data.results.countPhotoModerated

          this.getCountry();
				  this.emitMainPhoto();
			  })
		  },
    }
  }
</script>

<style lang="scss">
  .form__select-age {
    .vs__open-indicator {
      /*background-image: url(./img/arrow.svg);*/
      background-color: transparent;
      background-size: contain;
      border: none !important;
      height: 16px !important;
      width: 14px !important;
      margin: auto !important;
      top: auto !important;
      left: auto !important;
    }
  }

  .error__block {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -80px;
    height: 60px !important;
  }

  .profile__button {
    justify-content: center;

    &-container {
      width: 465px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;

      @media (max-width: 900px) {
        width: 100%;
        font-size: 10px;
        line-height: 12px;
      }

      &-description {
        margin-bottom: 10px;

        @media (max-width: 900px) {
          margin-bottom: 0;
        }
      }
    }
  }

  .not--necessary {
    &:before {
      content: none !important;
    }
  }

  .сol--gender {
    width: 70%;

    .form__gender {
      @media (max-width: 480px) {
        grid-gap: 20px;
      }
    }
  }
</style>