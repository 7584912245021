import {defineStore} from 'pinia'
import fetchClient from '../api';

export const profile = defineStore('profile', {
	state: () => ({
		success: '',
		countries: [],
		country: {},
		user: {},
		status: '',
	}),

	actions: {
		async getCountries() {
			try {
				let response = await fetchClient().get('dating/geo/city?countryId=1')
				let data = response.data;
				if (data.error) {
					this.status = 'error';
					return data;
				}
				this.country = data.result;
				return data;
			} catch (err) {
				this.status = 'error';
				return err;
			}
		},
		async getCountry(id = 1) {
			try {
				let response = await fetchClient().get(`dating/geo/city?id=${id}`);
				let data = response.data;

				if (data.error) {
					this.status = 'error';
					return data;
				}

				this.country = data.result;
				return data;

			} catch (err) {
				this.status = 'error';
				return err;
			}
		},
		async getUser() {
			try {
				let response = await fetchClient().get('dating/profile');
				let data = response.data;

				if (data.error) {
					this.status = 'error';
					return data;
				}

				this.user = data.result;
				return data;

			} catch (err) {
				this.status = 'error';

				return err;
			}
		},
		updateUser(data) {
			return new Promise((resolve, reject) => {
				fetchClient().post('dating/profile/update', data, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
					return resolve(response.data);
				}).catch(err => {
					this.status = 'error';
					return reject(err);
				});
			});
		},
		async deleteUser() {
			try {
				let response = await fetchClient().get('user/delete');
				let data = response.data;

				if (data.error) {
					this.status = 'error';
					return data;
				}

				return data;
			} catch (err) {
				this.status = 'error';

				return err;
			}
		}
	},
})