<template>
    <div class="not__wrapper ">
      <div class="content__prev">
      	<div class="content__title">
      		...упс!
      	</div>
      	<div class="content__text">
          {{ title }}
      	</div>
      </div>
      <div class="controls__block">
        <div class="controls__block-loading" @click="handlerReload">
          <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.87833 25.5C3.87833 14.6289 12.4494 3.83459 25.7909 3.83459C36.5649 3.83459 41.8937 11.7185 44.1625 15.9135H37.4259C36.9116 15.9135 36.4183 16.1155 36.0547 16.4751C35.691 16.8347 35.4867 17.3223 35.4867 17.8308C35.4867 18.3393 35.691 18.827 36.0547 19.1866C36.4183 19.5461 36.9116 19.7481 37.4259 19.7481H49.0608C49.5751 19.7481 50.0684 19.5461 50.432 19.1866C50.7957 18.827 51 18.3393 51 17.8308V6.32707C51 5.81857 50.7957 5.3309 50.432 4.97134C50.0684 4.61177 49.5751 4.40977 49.0608 4.40977C48.5465 4.40977 48.0533 4.61177 47.6896 4.97134C47.326 5.3309 47.1217 5.81857 47.1217 6.32707V13.2792C44.3913 8.51662 38.0697 0 25.7909 0C10.0332 0 0 12.7883 0 25.5C0 38.2117 10.0332 51 25.7909 51C33.3303 51 39.6055 48.0474 44.0733 43.5532C46.4441 41.1624 48.3247 38.3407 49.6116 35.2437C49.7083 35.0108 49.7578 34.7613 49.757 34.5095C49.7563 34.2578 49.7054 34.0086 49.6072 33.7762C49.5091 33.5439 49.3656 33.3329 49.1851 33.1554C49.0045 32.9779 48.7903 32.8373 48.5547 32.7416C48.3192 32.6459 48.0669 32.5971 47.8122 32.5978C47.5575 32.5985 47.3055 32.6489 47.0705 32.7459C46.8355 32.8429 46.6222 32.9848 46.4426 33.1633C46.2631 33.3419 46.1209 33.5536 46.0241 33.7865C44.9282 36.4245 43.3268 38.8282 41.3081 40.8652C37.5422 44.6614 32.2483 47.1654 25.7909 47.1654C12.4494 47.1654 3.87833 36.3711 3.87833 25.5Z" fill="white"/>
          </svg>
        </div>
      </div>
    </div>
</template>

<script>
	

	export default {
		inject: ['reload'],
    data() {
			return {
				
      }
    },
    props: ['title'],
    methods: {
			handlerReload() {
				this.reload();
      }
    }
	};
</script>

<style lang="scss">

  .controls__block-loading {
    cursor: pointer;
  }

</style>