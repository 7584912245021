<template>
	<div class="container container__main content__profile">
		<div class="profile profile__wrapper profile__form">
			<div class="profile__left">
				<div class="profile__title">моя анкета</div>
				<div class="profile__left-bottom">
					<div
						class="profile__image"
						:style="user !== {} ? {backgroundImage: `url(${userPhoto})`} : ''"
					>
						<div v-show="notPhoto" class="profile__image-notphoto"></div>
					</div>
					<div class="profile__info">
						<div class="profile__info-name">{{ user.username }}</div>
						<div class="profile__info-age card__info-description">
							<svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			          <path d="M2 18.262V20H18V18.272L14.894 19.025L10.034 18.021L5.057 19.025L2 18.262ZM2 16.2L5.104 16.975L10.04 15.979L14.859 16.975L18 16.214V15C18 14.7348 17.8946 14.4804 17.7071 14.2929C17.5196 14.1054 17.2652 14 17 14H3C2.73478 14 2.48043 14.1054 2.29289 14.2929C2.10536 14.4804 2 14.7348 2 15V16.2ZM13 12H17C17.7956 12 18.5587 12.3161 19.1213 12.8787C19.6839 13.4413 20 14.2044 20 15V22H0V15C0 14.2044 0.31607 13.4413 0.87868 12.8787C1.44129 12.3161 2.20435 12 3 12H7V9H13V12ZM11 12V11H9V12H11ZM10 8.4C9.20435 8.4 8.44129 8.08393 7.87868 7.52132C7.31607 6.95871 7 6.19565 7 5.4C7 4.295 8 2.495 10 0C12 2.495 13 4.295 13 5.4C13 6.19565 12.6839 6.95871 12.1213 7.52132C11.5587 8.08393 10.7956 8.4 10 8.4ZM9.998 6.419C10.55 6.419 10.998 5.939 10.998 5.347C10.998 4.953 10.664 4.31 9.998 3.419C9.331 4.31 8.998 4.953 8.998 5.347C8.998 5.939 9.446 6.419 9.998 6.419Z" fill="white"/>
			        	</svg>
		        	{{ user.age }} лет.
						</div>
					</div>
				</div>
			</div>
			<div class="profile__right">
				<Profile
				 	:isDelete="true"
					@imgChange="onChange"
				></Profile>
			</div>
		</div>
	</div>
</template>
{
<script>
	import Profile from "../components/ProfileComponent.vue"
	import { profile } from "../store/store_profile";
	import {useStore} from '../store/store_pinia';
	import {month} from "../json/data";

	export default {
		components: {
      Profile
		},
		data() {
			const store = profile();
			const store2 = useStore();
			return {
				store,
				store2,
				user: {
					profileParams: {
						searchAge: '',
						birthday: '',
					},
				},
				month,
				userPhoto: '',
				notPhoto: false,
			}
		},
		created() {
			this.getUser();
		},
		computed: {
			// targetAge() {
			// 	let age = this.user.profileParams.searchAge;
			// 	return {name: `${age} лет`, value: age};
			// },
			// searchRadius() {
			// 	let radius = this.user.profileParams.searchRadius;
			//
			// 	return {name: `${radius}км`, value: radius};
			// },
			// birthDayArr() {
			// 	return this.user.profileParams.birthday.split('-')
			// },
			// birthDay() {
			// 	return Number(this.birthDayArr[2]);
			// },
			// birthMonth() {
			// 	let month = this.month.filter(item => {
			// 		return item.value === Number(this.birthDayArr[1])
			// 	})
			// 	return month[0];
			// },
			// birthYear() {
			// 	return Number(this.birthDayArr[0]);
			// },
			// searchGender() {
			// 	let searchGender = this.user.profileParams.searchGender;
			// 	return searchGender === 1 ? {name: 'Девушка', value: searchGender} : {name: 'Парень', value: searchGender}
			// },
			// form () {
			// 	return {
			// 		targetAge: this.targetAge,
			// 		searchRadius: this.searchRadius,
			// 		searchGender: this.searchGender,
			// 		username: this.username,
			// 		cityId: this.user.profileParams.city_id,
			// 		birthDay: this.birthDay,
			// 		birthMonth: this.birthMonth,
			// 		birthYear: this.birthYear,
			// 	}
			// },
			// username() {
			// 	return this.store2.username;
			// },
		},
		methods: {
			getUser() {
				this.store.getUser().then((data) => {
					this.user = data.results;
					if(this.user.userPhoto.length === 0) {
						this.notPhoto = true
					}
				})
			},
			onChange(value) {
				this.userPhoto = value
			},
		}
	}
</script>