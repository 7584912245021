<template>
  <div class="container">
    <RegisterForm></RegisterForm>
    <Preview></Preview>
    <Advantage></Advantage>
    <div class="register__footer">
      <div class="register__footer-title">Хочешь воспользоваться шансом?</div>
      <a href="#" id="reg-button" class="button register__footer-button">
        <span>Мне повезет! Зарегистрироваться</span>
      </a>

      <router-link to="/login" class="login-block">
        <div class="login-block__title">Уже есть анкета?</div>
        <div class="login-block__btn">
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.75 15.9688C6.55109 15.9688 6.36032 15.8897 6.21967 15.7491C6.07902 15.6084 6 15.4177 6 15.2188C6 15.0198 6.07902 14.8291 6.21967 14.6884C6.36032 14.5478 6.55109 14.4688 6.75 14.4688H13C13.5304 14.4688 14.0391 14.258 14.4142 13.883C14.7893 13.5079 15 12.9992 15 12.4688V4.46875C15 3.93832 14.7893 3.42961 14.4142 3.05454C14.0391 2.67946 13.5304 2.46875 13 2.46875H6.75C6.55109 2.46875 6.36032 2.38973 6.21967 2.24908C6.07902 2.10843 6 1.91766 6 1.71875C6 1.51984 6.07902 1.32907 6.21967 1.18842C6.36032 1.04777 6.55109 0.96875 6.75 0.96875H13C13.9283 0.96875 14.8185 1.3375 15.4749 1.99388C16.1313 2.65025 16.5 3.54049 16.5 4.46875V12.4688C16.5 13.397 16.1313 14.2872 15.4749 14.9436C14.8185 15.6 13.9283 15.9688 13 15.9688H6.75ZM6.741 4.66775C6.81335 4.60089 6.89816 4.54894 6.9906 4.51487C7.08303 4.4808 7.18127 4.46527 7.2797 4.46917C7.37813 4.47307 7.47484 4.49632 7.56428 4.5376C7.65373 4.57888 7.73417 4.63738 7.801 4.70975L10.801 7.95975C10.929 8.09832 11.0002 8.28007 11.0002 8.46875C11.0002 8.65743 10.929 8.83918 10.801 8.97775L7.801 12.2277C7.73409 12.3 7.6536 12.3584 7.56412 12.3996C7.47464 12.4408 7.37792 12.464 7.27949 12.4678C7.18106 12.4716 7.08284 12.456 6.99045 12.4218C6.89806 12.3877 6.81329 12.3357 6.741 12.2688C6.66871 12.2018 6.6103 12.1213 6.56912 12.0319C6.52793 11.9424 6.50477 11.8457 6.50097 11.7472C6.49328 11.5485 6.56487 11.3548 6.7 11.2087L8.538 9.21775H0.75C0.551088 9.21775 0.360322 9.13873 0.21967 8.99808C0.0790177 8.85743 0 8.66666 0 8.46775C0 8.26884 0.0790177 8.07807 0.21967 7.93742C0.360322 7.79677 0.551088 7.71775 0.75 7.71775H8.537L6.699 5.72775C6.63214 5.6554 6.58019 5.57059 6.54612 5.47815C6.51205 5.38572 6.49652 5.28748 6.50042 5.18905C6.50432 5.09062 6.52757 4.99391 6.56885 4.90447C6.61013 4.81502 6.66863 4.73458 6.741 4.66775Z" fill="#001E3A"/>
          </svg>
          <div>Войти</div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
  import RegisterForm from '../components/RegisterFormComponent.vue'
  import Preview from "../components/PreviewComponent.vue"
  import Advantage from "../components/AdvantageComponent.vue"

  export default {
    components: {
      RegisterForm,
      Preview,
      Advantage,
    },
  };
</script>

<style lang="scss" scoped>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 84px;
    gap: 30px;
    grid-gap: 30px;
    height: auto;

    @media (max-width: 768px) {
      padding-top: 0;
      gap: 32px;
      grid-gap: 32px;
    }

    @media (max-width: 320px) {
      padding-top: 0;
      gap: 20px;
      grid-gap: 20px;
    }
  }

  .register__footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 125px;
    grid-gap: 125px;

    &-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }

    &-button {
      width: 359px;
      padding: 16px;

      @media (max-width: 480px) {
        width: 100%;
        text-align: center;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
      grid-gap: 10px;
    }
  }

  .login-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 29px;
    grid-gap: 29px;

    &__title {
      font-weight: 500;
      font-size: 11px;
      line-height: 100%;
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      grid-gap: 6px;
      width: 170px;
      height: 50px;
      background: #F7FEFF;
      border: 1px solid #B7F1FF;
      border-radius: 25px;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
    }
  }
</style>e
