<template>
  <div class="form__gender">
    <div class="form__gender-block">
      <svg width="20" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 .969c-3.666 0-6.446.862-8.313 2.625C1.822 5.357 1 7.927 1 10.969v.906c-.572.544-1 1.244-1 2.094 0 1.26.89 2.154 2 2.594.37 1.167.773 2.393 1.22 3.437.485 1.142.924 2.048 1.53 2.69a7.192 7.192 0 0 0 10.5 0c.606-.642 1.013-1.548 1.5-2.69.446-1.044.88-2.27 1.25-3.436 1.11-.44 2-1.334 2-2.594 0-.846-.43-1.547-1-2.094v-.907c0-2.824-.643-4.834-1.78-6.156-.966-1.12-2.255-1.58-3.532-1.72l-.782-1.56-.28-.564H12zm-.594 2.063.688 1.375c.333.666.463 1.14.468 1.343.006.204.013.12-.03.158-.087.073-.93.29-1.97.343-1.04.054-2.264.028-3.406.47C6.016 7.163 5.013 8.348 5 9.97h2c.008-.986.237-1.128.875-1.375.638-.247 1.738-.32 2.813-.375 1.074-.055 2.183.01 3.125-.78.47-.397.77-1.08.75-1.75-.005-.148-.04-.29-.063-.44a3.23 3.23 0 0 1 1.188.876C16.413 6.969 17 8.45 17 10.969v1.844l.5.28c.304.177.5.496.5.876a.98.98 0 0 1-.906 1l-.688.03-.187.657a37.553 37.553 0 0 1-1.282 3.532c-.45 1.056-.967 1.956-1.125 2.124-2.13 2.25-5.497 2.25-7.625 0-.16-.168-.675-1.068-1.126-2.125a37.879 37.879 0 0 1-1.28-3.532l-.188-.657-.688-.03a.98.98 0 0 1-.905-1c0-.373.193-.697.5-.874l.5-.28v-1.845c0-2.697.684-4.636 2.063-5.938 1.28-1.21 3.37-1.9 6.343-2zM7 12.969a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" fill="#fff"/>
      </svg>
      <input id="man" name="gender" type="radio" class="form__gender-radio" value="1" v-model="gender" @change="emitGender">
      <label for="man" class="checkbox-label checkbox-label__man"></label>
      <div class="form__gender-label">Я парень</div>
    </div>
    <div class="form__gender-block">
      <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.125.969c-3.304 0-6.984.562-9.72 3.594C1.673 7.595 0 12.849 0 21.969v1h8.656c.99.625 2.103 1 3.344 1 1.24 0 2.355-.383 3.344-1H25v-1c0-8.125-1.57-12.844-3.625-15.594-1.81-2.42-3.892-3.094-5.438-3.25L15 1.469l-.28-.5h-.595zm-.563 2.063.813 1.437.28.5h.595c1.01 0 2.848.34 4.53 2.594 1.606 2.146 3.02 6.236 3.158 13.406h-5.375c.11-.14.21-.292.313-.438C19.233 18.594 20 16.176 20 13.969h-2c0 1.722-.644 3.827-1.75 5.406-1.106 1.58-2.585 2.594-4.25 2.594-1.663 0-3.143-1.01-4.25-2.594C6.643 17.793 6 15.679 6 13.969c0-.444.085-.667.22-.844.132-.177.364-.33.717-.468.707-.28 1.9-.395 3.157-.5 1.258-.106 2.57-.206 3.75-.75C15.024 10.862 16 9.629 16 7.969h-2c0 1.044-.274 1.304-.97 1.625-.694.32-1.882.458-3.124.563-1.242.104-2.55.163-3.72.624-.583.23-1.148.578-1.56 1.126-.416.547-.626 1.28-.626 2.062 0 2.198.768 4.59 2.125 6.53.11.16.225.318.344.47H2.06c.135-8.163 1.71-12.696 3.844-15.063 2.088-2.314 4.783-2.815 7.656-2.874h.002zM9 13.969a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" fill="#fff"/>
      </svg>
      <input id="girl" name="gender" type="radio" class="form__gender-radio" value="2" v-model="gender" @change="emitGender">
      <label for="girl" class="checkbox-label checkbox-label__girl"></label>
      <div class="form__gender-label">Я девушка</div>
    </div>
  </div>
</template>

<script>
	import { profile } from "../store/store_profile";
	import { useStore } from "../store/store_pinia";

	export default {
		name: "toggleGenderComponent",
    data() {
	    let store = profile();
	    const store2 = useStore();
			return {
				store,
        store2,
				gender: null,
      }
    },
    // props: {
		// 	genderDefault: {
		// 		type: Number,
    //     default: null,
    //   }
    // },
    mounted() {
			// this.setGender();
	    this.getUser()
    },
		methods: {
			emitGender() {
				this.$emit('genders', this.gender)
      },
      setGender() {
        console.log('ok')
        this.gender = this.genderDefault;
      },
			getUser() {
				this.store.getUser().then((data) => {
					if (this.store2.isAuth) {
						if (data.results.profileParams !== null) {
							this.gender = data.results.profileParams.gender;
						}
					} else {
						this.gender = localStorage.getItem('gender') !== null ? localStorage.getItem('gender') : '';
					}
				})
			},
    }
	}
</script>

<style scoped>

</style>